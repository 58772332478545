<template>
  <div class="app-questions list">
    <PageHeader
      title="Média salarié"
      description="Choix des actualités issues de la base de médias Maviepro"
    />
    <NewsSubmenu />
    <PageContent>
      <MVPTable :gridData="gridData" :options="gridOptions" />
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

import MVPTable from '@/components/agGrid/MVPTable.vue'
import AG_GRID_LOCALE_FR from '@/assets/ag-grid.locale.fr.json'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import ButtonCellRenderer from '@/components/agGrid/ButtonCellRenderer.vue'
import ImageCellRenderer from '@/components/agGrid/ImageCellRenderer.vue'
import NewsSubmenu from './NewsSubmenu.vue'
import ListRowDetail from './ListRowDetail.vue'
import { fr } from 'date-fns/locale'

export default {
  name: 'app-articles-media-mvp',
  components: {
    PageHeader,
    NewsSubmenu,
    PageContent,
    ButtonCellRenderer, //eslint-disable-line
    ListRowDetail, //eslint-disable-line
    ImageCellRenderer, //eslint-disable-line
    MVPTable
  },
  computed: {
    ...mapGetters([
      'roles',
      'domain'
    ])
    // domain () {
    //   this.getArticles()
    //   return this.$store.getters.domain
    // }
  },
  data () {
    return {
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      loading: false,
      articles: [],
      gridData: null,
      gridOptions: {
        rowModelType: 'clientSide',
        localeText: AG_GRID_LOCALE_FR,
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        columnDefs: [
          {
            cellRenderer: 'agGroupCellRenderer',
            width: 20,
            suppressMenu: true
          },
          {
            headerName: 'Illustration',
            field: 'thumbnail',
            cellRenderer: 'ImageCellRenderer',
            cellRendererParams: {
              image: {
                classes: 'h-6 w-6',
                path: 'thumbnails'
              }
            },
            width: 40
          },
          {
            headerName: 'Titre',
            field: 'title',
            filter: 'agSetColumnFilter',
            sortable: true
          },
          {
            headerName: 'Date',
            field: 'publication_date',
            filter: 'agDateColumnFilter',
            valueFormatter: params => format(parseISO(params.value), 'dd/MM/yyyy', { locale: fr }),
            filterValueGetter: params => parseISO(params.value),
            sortable: true
          },
          {
            headerName: 'Action',
            field: 'button',
            cellRenderer: 'ButtonCellRenderer',
            cellRendererParams: {
              onClick: this.duplicate
            },
            width: 200
          },
          {
            headerName: 'Dupliqué ?',
            field: 'duplicated_by',
            valueGetter: params => {
              const duplicatedBy = params.data.duplicated_by
              const domainUuid = this.domain.uuid
              return duplicatedBy && duplicatedBy.includes(domainUuid) ? 'Oui' : 'Non'
            },
            sortable: true,
            filter: true
          }
        ],
        defaultColDef: {
          resizable: true,
          floatingFilter: true
        }
      }
    }
  },
  mounted () {
    this.getArticles()
  },
  methods: {
    getArticles () {
      this.loading = true
      http.get('/article/media-mvp').then(res => {
        this.loading = false
        this.articles = res.data
        this.gridData = res.data
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des articles',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    goToArticle (article) {
      this.$router.push({ path: `/app/articles/${article.uuid}` })
    },
    duplicate (article) {
      this.$buefy.dialog.confirm({
        title: 'Duplication',
        message: 'Voulez-vous vraiment dupliquer cet article ?<br><br>En confirmant, l\'article sera automatiquement dupliqué sur votre domaine et vous serez redirigé vers l\'édition de ce dernier.',
        confirmText: 'Dupliquer',
        cancelText: 'Annuler',
        type: 'is-danger',
        onConfirm: () => {
          http.post(`/article/${article.uuid}/duplicate`).then(res => {
            this.$router.push({ path: `/app/articles/${res.data.uuid}` })
          }, err => {
            console.log(err)
          })
        }
      })
    }
  }
}
</script>
